<template>
  <div class="account-page max-container w-full pb-10 pt-5 space-y-10">
    <div
      class="flex flex-col xl:flex-row space-y-8 xl:space-y-0 xl:space-x-8 items-stretch"
    >
      <div class="flex-grow bg-white rounded-xl">
        <div
          class="flex items-center justify-between border-b border-theme-border border-opacity-40 px-4 sm:px-8 2xl:px-12 py-6"
        >
          <div class="flex items-center">
            <div
              class="flex items-center justify-center rounded-full w-12 h-12 text-indigo-500 bg-indigo-200 flex-shrink-0"
            >
              <span class="text-lg font-medium">{{ userInitials }}</span>
            </div>
            <div class="pl-5 text-base">
              <h3 class="text-theme-body font-semibold">
                Hello {{ userName }}!
              </h3>
              <p class="text-theme-body">Customer Since {{ coins.since }}</p>
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 px-4 sm:px-8 2xl:px-12 py-6 gap-6 max-w-5xl"
        >
          <div class="flex items-center">
            <div
              class="flex items-center justify-center rounded-full w-12 h-12 text-indigo-500 flex-shrink-0 order-bg"
            >
              <Icon name="account-orders" class="w-6 h-6" />
            </div>
            <div class="pl-4 text-base">
              <h3 class="text-theme-secondary font-semibold">
                {{ commaFormat(coins.orders) }}
              </h3>
              <p class="text-theme-body">Orders</p>
            </div>
          </div>

          <div class="flex items-center" v-if="cart.canApplyPoints == true">
            <div
              class="flex items-center justify-center rounded-full w-12 h-12 text-indigo-500 flex-shrink-0 coins-bg"
            >
              <Icon name="medusa-icon-white" class="w-6 h-6" />
            </div>
            <div class="pl-4 text-base">
              <h3 class="text-theme-secondary font-semibold">
                {{ commaFormat(coins.coins_balance) }}
              </h3>
              <p class="text-theme-body">Coins Balance</p>
            </div>
          </div>

          <div class="flex items-center" v-if="coins.company_credit > 0">
            <div
              class="flex items-center justify-center rounded-full w-12 h-12 text-indigo-500 flex-shrink-0 cart-bg"
            >
              <Icon name="account-cart" class="w-6 h-6" />
            </div>
            <div class="pl-4 text-base">
              <h3 class="text-theme-secondary font-semibold">
                ${{ commaFormat(Number(coins.company_credit || 0).toFixed(2)) }}
              </h3>
              <p class="text-theme-body">Store Credit</p>
            </div>
          </div>
        </div>
      </div>
      <Support class="w-full xl:w-96 flex-shrink-0 rounded-xl" />
    </div>
    <div class="flex flex-col space-y-8  items-start orders-table-data ">
      <ul
        class="w-full flex-shrink-0 flex items-stretch  p-2  bg-white rounded-xl overflow-hidden gap-2 flex-col lg:flex-row"
      >
        <li>
          <router-link
            to="/account/orders"
            class="nav-item"
            exact-active-class="active"
          >
            <Icon name="package" class="h-4.5 w-4.5" />
            <span>Orders</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/account/stores"
            class="nav-item"
            exact-active-class="active"
          >
            <Icon name="shop" class="h-4.5 w-4.5" />
            <span>Locations</span>
          </router-link>
        </li>
        <li v-if="cart.IsUSA">
          <router-link
            to="/account/payments"
            class="nav-item"
            exact-active-class="active"
          >
            <Icon name="credit-card" class="h-4.5 w-4.5" />
            <span>Payments</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/account/users"
            class="nav-item"
            exact-active-class="active"
          >
            <Icon name="user-account" class="h-4.5 w-4.5" />
            <span>Users</span>
          </router-link>
        </li>
      </ul>
      <div class="w-full flex-grow space-y-4">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { computed } from "vue";
import Mymixin from "@/modules/mixins";
import Support from "./modules/Support.vue";

export default {
  name: "Account",
  mixins: [Mymixin],
  computed: {
    ...mapGetters(["coins", "cart"]),
  },
  setup() {
    const userName = localStorage.getItem("username") || "";
    const userInitials = computed(() => {
      return userName
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    });
    return {
      userName,
      userInitials,
    };
  },
  components: { Support },
};
</script>

<style lang="scss" scoped>
.account-page {
  .order-bg {
    background: #f1ddd1;
  }
  .money-bg {
    background: #e5ffd9;
  }
  .coins-bg {
    background: #f7f2ed;
  }
  .cart-bg {
    background: #fff2d9;
  }

  .nav-item {
    @apply flex items-center space-x-4 border-transparent bg-white pl-6 pr-8 py-3 text-theme-body text-md transition-colors leading-none rounded-xl;
    &:hover,
    &.active {
      @apply border-theme-primary bg-theme-primary bg-opacity-10 text-theme-secondary;
    }
  }
}
</style>
